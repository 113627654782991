import type { MouseEvent as ReactMouseEvent } from 'react';

import { isNativeMouseEvent } from './is-native-mouse-event';
import { isReactMouseEvent } from './is-react-mouse-event';

export function isMouseEvent<T extends MouseEvent | ReactMouseEvent, K extends MouseEventInit>(
  event: unknown,
  eventInitDict: K,
): event is T & NonNullable<K> {
  if (!isNativeMouseEvent(event) && !isReactMouseEvent(event)) {
    return false;
  }

  for (const [key, value] of Object.entries(eventInitDict)) {
    if (typeof value !== 'undefined' && event[key as keyof typeof event] !== value) {
      return false;
    }
  }

  return true;
}
