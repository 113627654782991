import cx from 'classnames';
import type { ComponentPropsWithRef, ComponentPropsWithoutRef, ElementType, PropsWithChildren } from 'react';

import styles from './container.module.scss';

type ContainerProps<T extends ElementType = 'div'> = ComponentPropsWithoutRef<T> &
  PropsWithChildren<{
    as?: T;
    fullWidthSm?: boolean;
    fullWidthMd?: boolean;
    fullWidthLg?: boolean;
  }>;

function Container<T extends ElementType = 'div'>({
  as,
  fullWidthSm,
  fullWidthMd,
  fullWidthLg,
  children,
  className,
  ...elementProps
}: ContainerProps<T> & ComponentPropsWithRef<T>) {
  const fullWidthClasses = {
    [styles.fullWidthSm]: fullWidthSm,
    [styles.fullWidthMd]: fullWidthMd,
    [styles.fullWidthLg]: fullWidthLg,
  };
  const Component = as || 'div';
  return (
    <Component className={cx(styles.container, fullWidthClasses, className)} {...elementProps}>
      {children}
    </Component>
  );
}

export default Container;
