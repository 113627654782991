import { createContext } from 'react';
import type { NavItem } from '@hubcms/domain-navigation';

export type NavigationContextValue = {
  isAsideMenuOpen: boolean;
  toggleIsAsideMenuOpen: () => void;
  subnavItems: NavItem[];
};

export const NavigationContext = createContext<NavigationContextValue | null>(null);
