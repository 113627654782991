import pino, { levels } from 'pino';

const defaultLogLevel = 'warn';
let logLevel = (process.env.LOG_LEVEL || '').toLowerCase();
if (!Object.keys(levels.values).includes(logLevel)) {
  logLevel = defaultLogLevel;
}
const pinoOpts =
  process.env.NODE_ENV === 'development'
    ? {
        transport: {
          target: 'pino-pretty',
          options: {
            colorize: true,
          },
        },
      }
    : { level: logLevel };
const logger = pino(pinoOpts);
logger.info(`log level is set to ${logLevel}`);

function parseArg(arg: unknown) {
  if (['string', 'number'].includes(typeof arg)) {
    return arg?.toString();
  } else if (arg instanceof Error) {
    return arg.message;
  } else if (typeof arg === 'object' && arg !== null) {
    return JSON.stringify(arg);
  }
  return '';
}

export function log(...args: unknown[]): void {
  logger.info(args.map(parseArg).join(' '));
}
export function error(...args: unknown[]): void {
  logger.error(args.map(parseArg).join(' '));
}
export function warn(...args: unknown[]): void {
  logger.warn(args.map(parseArg).join(' '));
}
