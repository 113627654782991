import type { ClickNavigationEvent, ClickOutboundEvent } from '@hubcms/domain-tracking';

import { type ClickSettingsData, createClickNavigationEvent } from './create-click-navigation-event';
import { type ClickOutboundData, createClickOutboundEvent } from './create-click-outbound-event';

export type ClickData = ClickSettingsData | ClickOutboundData;

export type ClickOptions = {
  isSiteDomain?: boolean;
};

export function createNavigationOrOutboundClickEvent(
  data: ClickData,
  { isSiteDomain = false }: ClickOptions,
): ClickNavigationEvent | ClickOutboundEvent {
  if (isSiteDomain) {
    return createClickNavigationEvent(data);
  }
  return createClickOutboundEvent(data);
}
