import { isHTMLEventTarget } from './is-html-event-target';

export function isTargetSelf<T extends EventTarget>(
  eventTarget: T | null,
): eventTarget is T & HTMLElement & { target: null | '_self' } {
  if (!isHTMLEventTarget(eventTarget)) {
    return false;
  }

  return [null, '_self'].includes(eventTarget.getAttribute('target'));
}
