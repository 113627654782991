import { isHTMLAnchorEventTarget } from './is-html-anchor-event-target';

export function isNonEmptyHref<T extends EventTarget>(
  eventTarget: T | null,
): eventTarget is T & HTMLAnchorElement & { href: string extends '' ? never : string } {
  if (!isHTMLAnchorEventTarget(eventTarget)) {
    return false;
  }

  return eventTarget.href.length > 0;
}
