import type { ClickNavigationEvent } from '@hubcms/domain-tracking';

export type ClickSettingsData = Omit<ClickNavigationEvent, 'event'>;

export function createClickNavigationEvent(data: ClickSettingsData): ClickNavigationEvent {
  return {
    event: 'click_navigation',
    clickitemblock: data.clickitemblock,
    clickitemname: data.clickitemname,
    clickitemurl: data.clickitemurl,
  };
}
