export function addSearchParams(search: string, params: Record<string, string | number | boolean | null | undefined>): string {
  const searchParams = new URLSearchParams(search);

  Object.entries(params).forEach(([name, value]) => {
    if (value !== null && value !== undefined) {
      searchParams.set(name, String(value));
    }
  });

  // More info about why we do a replace here can be found in
  // https://url.spec.whatwg.org/#interface-urlsearchparams
  return searchParams.toString().replace(/\+/, '%20');
}
