import type { IDidomiObject } from '@didomi/react';

import { getDidomiOnReady } from './get-didomi-on-ready';

let didomiOnReadyPromise: Promise<IDidomiObject> | undefined;

export function onDidomiReadyPromise(): Promise<IDidomiObject> {
  if (!didomiOnReadyPromise) {
    didomiOnReadyPromise = new Promise(resolve => {
      window.didomiOnReady = getDidomiOnReady();
      window.didomiOnReady.push(resolve);
    });
  }

  return didomiOnReadyPromise;
}

export function onDidomiReady(listener: () => void): void {
  // eslint-disable-next-line promise/catch-or-return
  onDidomiReadyPromise().then(listener);
}
