import { createUrl } from '@hubcms/utils-url';
import { getSavedMySectionPreferences } from './mySectionCookies';

function getPathName(url: string) {
  return createUrl(url).pathname;
}

export function getSectionPreference(parentSectionUrl: string) {
  if (typeof document === 'undefined') {
    return parentSectionUrl;
  }
  const preferences = Object.fromEntries(
    Object.entries(getSavedMySectionPreferences()).map(([overview, preference]) => [getPathName(overview), preference]),
  );
  return preferences[getPathName(parentSectionUrl)] ?? parentSectionUrl;
}
