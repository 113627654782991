import { DateTime as ChameleonDateTime } from '@mediahuis/chameleon-react';
import { dateFnsLocale, timeZone } from '@hubcms/brand';

type DateTimeProps = {
  date: string;
  className?: string;
  format?: string;
};

const DateTime = (props: DateTimeProps) => {
  return <ChameleonDateTime locale={dateFnsLocale} timeZone={timeZone} {...props} />;
};

export default DateTime;
