import { useMemo, useCallback } from 'react';
import getConfig from 'next/config';
import { loginLabel, logoutLabel, authMethod } from '@hubcms/brand';

import { useAuth } from '@hubcms/data-access-auth';
import type { NavItem } from '@hubcms/domain-navigation';
import { useEnv } from '@hubcms/data-access-env';
import { sendTrackingEvent } from '@hubcms/data-access-tracking';
import { createMenuEvent } from '@hubcms/utils-tracking';
import { UserMenu } from '@hubcms/ui-navigation';

type UserMenuWrapperProps = {
  loginUrl: string;
  userMenuItems: NavItem[];
};

function UserMenuWrapper({ loginUrl, userMenuItems }: UserMenuWrapperProps) {
  const env = useEnv();
  const { isAuthenticated, user, logout, login } = useAuth();
  const { ZEPHR_SDK_VERSION } = getConfig();
  const logoutHref = useMemo(() => {
    if (authMethod === 'oauth' && env?.LOGOUT_BASE_URL && typeof window !== 'undefined') {
      return `${env.LOGOUT_BASE_URL}${encodeURIComponent(window.location.href.toString())}`;
    }

    return '';
  }, [env?.LOGOUT_BASE_URL]);
  const isZephrSdkEnabled = ZEPHR_SDK_VERSION === 'v1';

  const handleLogin = useCallback(async () => {
    await sendTrackingEvent(
      createMenuEvent({
        eventlabel: 'login',
      }),
    );
    login(loginUrl);
  }, [login, loginUrl]);

  const handleLogout = useCallback(async () => {
    await sendTrackingEvent(createMenuEvent({ eventlabel: 'logout' }));
    logout(logoutHref);
  }, [logout, logoutHref]);

  if (!authMethod || authMethod === 'none') {
    return null;
  }

  return (
    <UserMenu
      isAuthenticated={isAuthenticated}
      user={user}
      style={{ zIndex: isZephrSdkEnabled ? 300 : 900 }}
      loginLabel={loginLabel}
      logoutLabel={logoutLabel}
      userMenuItems={userMenuItems}
      loginAction={handleLogin}
      logoutAction={handleLogout}
    />
  );
}

export default UserMenuWrapper;
