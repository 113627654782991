import Cookies from 'js-cookie';
import { error } from '@hubcms/utils-monitoring';

import type { MySectionPreferences } from '../domain/my-section-preferences';
import type { MySectionSelections } from '../domain/my-section-selections';

const MY_SECTION_PREFS_COOKIE_NAME = 'my-section-prefs';
const MY_SECTION_SELECTION_COOKIE_NAME = 'my-section-selection';

export function getCookieValue<T>(cookieName: string, defaultValue: T): T {
  try {
    const value = Cookies.get(cookieName);
    return value ? JSON.parse(decodeURIComponent(value)) : defaultValue;
  } catch (e) {
    error(`unexpected error while trying to parse ${cookieName} cookie`, e);
    return defaultValue;
  }
}

export function saveCookieValue<T>(cookieName: string, data: T, options = { expires: 365, path: '/' }): void {
  Cookies.set(cookieName, JSON.stringify(data), options);
}

// get
export function getSavedMySectionPreferences(): MySectionPreferences {
  return getCookieValue<MySectionPreferences>(MY_SECTION_PREFS_COOKIE_NAME, {});
}

export function getSavedMySectionSelection(): MySectionSelections[] {
  return getCookieValue<MySectionSelections[]>(MY_SECTION_SELECTION_COOKIE_NAME, []);
}

// save
export function saveMySectionPreferences(mySectionPreferences: MySectionPreferences): void {
  saveCookieValue(MY_SECTION_PREFS_COOKIE_NAME, mySectionPreferences);
}

export function saveMySectionSelections(mySectionSelection: MySectionSelections[]): void {
  saveCookieValue(MY_SECTION_SELECTION_COOKIE_NAME, mySectionSelection);
}
