import type {
  MenuItemFragment,
  OnArticleMenuItem,
  OnLinkMenu,
  OnSectionMenu,
  OnSpacerMenu,
  SubmenuItemsFragment,
} from '@hubcms/domain-cook';
import { createUrl } from '@hubcms/utils-url';

function isSectionMenuItem(item: OnSectionMenu | OnArticleMenuItem | OnLinkMenu | OnSpacerMenu | SubmenuItemsFragment) {
  return item.__typename === 'SectionMenuItem';
}

function findByPathname(items: OnSectionMenu[], slug: string) {
  const selectedSection = items.find(item => slug.includes(createUrl(item.href).pathname));

  if (selectedSection) {
    if (createUrl(selectedSection.href).pathname === slug) {
      return selectedSection;
    }

    const subsectionMenuItems = selectedSection.subcats.filter(item => isSectionMenuItem(item)) as OnSectionMenu[];
    if (subsectionMenuItems.length !== 0) {
      return findByPathname(subsectionMenuItems, slug);
    }
  }

  return null;
}

export function parseNavigationTree(
  menuItems: MenuItemFragment[],
  slug: string,
): { title: string; navItems: MenuItemFragment[] | SubmenuItemsFragment[] } {
  const sectionMenuItems = menuItems.map(({ item }) => item).filter(item => isSectionMenuItem(item));
  const currentMenuItem = findByPathname(sectionMenuItems as OnSectionMenu[], slug);

  return {
    title: currentMenuItem?.label || '',
    navItems: currentMenuItem?.subcats || [],
  };
}
