import { useContext } from 'react';
import { AdElement } from '@hubcms/ui-ads';
import { AdPropsContext } from './ad-context';

export function AdServer() {
  const { adFormat, isSticky = false, adStickyTop, className, style } = useContext(AdPropsContext);

  return (
    <AdElement
      id=""
      adFormat={adFormat}
      isPreview={false}
      isSticky={isSticky}
      adStickyTop={adStickyTop}
      className={className}
      hasNoReservedHeight={false}
      style={style}
    />
  );
}
