import { useCallback, useState } from 'react';

export function useOpenMenus() {
  const [openMenuName, setOpenMenuName] = useState('');

  const isMenuOpen = useCallback(
    (menuName: string) => {
      return openMenuName === menuName;
    },
    [openMenuName],
  );

  const closeMenu = () => {
    setOpenMenuName('');
  };

  const toggleMenu = (menuName: string) => {
    setOpenMenuName(currentOpenMenuName => {
      return menuName === currentOpenMenuName ? '' : menuName;
    });
  };

  return {
    closeMenu,
    toggleMenu,
    isMenuOpen,
  };
}
