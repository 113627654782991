import type { IDidomiObject } from '@didomi/react';

import { getDidomiAsync, getDidomiSync } from './get-didomi';

type DidomiPreferencesView = 'information' | 'purposes' | 'vendors';

export async function showDidomiPreferencesAsync(view?: DidomiPreferencesView): Promise<void> {
  const Didomi = await getDidomiAsync();

  showDidomiPreferences(Didomi, view);
}

export function showDidomiPreferencesSync(view?: DidomiPreferencesView): void {
  const Didomi = getDidomiSync();

  if (typeof Didomi === 'undefined') {
    return;
  }

  showDidomiPreferences(Didomi, view);
}

export function showDidomiPreferences(Didomi: IDidomiObject, view?: DidomiPreferencesView): void {
  Didomi.preferences.show(view);
}
