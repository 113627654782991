import getConfig from 'next/config';

import type { NavigationVersion } from '@hubcms/domain-navigation';

export function getNavigationVersion(): NavigationVersion {
  const { publicRuntimeConfig } = getConfig();

  return isValidNavigationVersion(publicRuntimeConfig.NEXT_PUBLIC_NAVIGATION_VERSION)
    ? publicRuntimeConfig.NEXT_PUBLIC_NAVIGATION_VERSION
    : '1';
}

function isValidNavigationVersion(version?: unknown): version is NavigationVersion {
  return version === '1' || version === '2';
}
