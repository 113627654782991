import type { NavColumn } from '@hubcms/domain-navigation';
import type { MenuItemFragment } from '@hubcms/domain-cook';

import { normalizeMenuItem } from './normalizeMenuItem';

export function mapNavColumn(menuItemFragment: MenuItemFragment, idx: number): NavColumn {
  const normalizedMenuItem = normalizeMenuItem(menuItemFragment, idx);
  return {
    heading: normalizedMenuItem,
    subItems: normalizedMenuItem.children,
  };
}
