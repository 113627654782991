import { type ReactNode, type ReactElement, createContext, useContext, useState } from 'react';

// for lack of a better type ATM.
type ModalData = {
  isOpen: boolean;
  open: (content: ReactElement) => void;
  close: () => void;
  content: ReactElement;
};

// @ts-expect-error TS2345
const ModalContext = createContext<ModalData>(null);

export function ModalProvider(props: { children?: ReactNode }): ReactElement {
  const [content, setContent] = useState<ReactElement | null>(null);
  return (
    <ModalContext.Provider
      // @ts-expect-error TS2322
      value={{ isOpen: content !== null, open: setContent, close: () => setContent(null), content }}
      {...props}
    />
  );
}

export function useModal(): ModalData {
  return useContext(ModalContext);
}
