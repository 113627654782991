import cx from 'classnames';
import type { FC } from 'react';
import { footerCopyrightLogoUrl } from '@hubcms/brand';

import { NavigationItem } from '../NavigationItem';

import styles from './copyright-banner.module.scss';
import type { CopyrightBannerProps } from './types';

const CopyrightBanner: FC<CopyrightBannerProps> = ({ items, className, ...props }) => {
  return (
    <div className={cx(styles.copyrightBanner, className)} {...props}>
      <svg viewBox="0 0 425 78" width="130" height="24" className={styles.mhLogo}>
        <use href={`/logos/${footerCopyrightLogoUrl}`}></use>
      </svg>
      {items.map(({ uniqueId, text, url, target }) => (
        <NavigationItem key={uniqueId} href={url} target={target}>
          {text}
        </NavigationItem>
      ))}
    </div>
  );
};

export default CopyrightBanner;
