/* eslint-disable camelcase */
import type { SubscriptionShopUtmParams } from '@hubcms/domain-tracking';

export type SubscriptionShopUtmData = Pick<SubscriptionShopUtmParams, 'utm_artid' | 'utm_internal'>;

export function createSubscriptionShopUtmParams(data: SubscriptionShopUtmData): SubscriptionShopUtmParams {
  return {
    utm_artid: data.utm_artid,
    utm_internal: data.utm_internal,
  };
}
