import type { TrackingData } from '@hubcms/domain-tracking';

import type { NonNullish } from '../domain/non-nullish';

const urlRegex = /^https?:\/\/|^\//i;

export function isClickData(
  data?: TrackingData | null,
): data is TrackingData & NonNullish<Pick<TrackingData, 'clickitemname' | 'clickitemurl'>> {
  return Boolean(data && data.clickitemname && data.clickitemurl && urlRegex.test(data.clickitemurl));
}
