export class Deferred<T = unknown> {
  resolve!: (value?: T) => void;
  reject!: (value?: unknown) => void;
  promise: Promise<T | undefined>;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
}
