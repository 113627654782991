type ThemeDataAttributes = Record<`data-theme-${string}`, ''>;

export const isThemed = (theme: string): boolean => theme !== '' && theme !== 'none';

export function getThemeDataAttributes(theme: string = 'none'): ThemeDataAttributes {
  if (!isThemed(theme)) {
    return {};
  }
  const dataAttributes: ThemeDataAttributes = {};
  // The reason for not using '' is that otherwise React sets attribute="value"
  // so true would result in data-theme-accent="true", which is not needed
  // and false would result in data-theme-accent="false", which results in the theme being applied anyway

  dataAttributes[`data-theme-${theme}`] = '';

  return dataAttributes;
}
