import type { MenuItemFragment, SubmenuItemsFragment } from '@hubcms/domain-cook';
import { isMenuItem } from '@hubcms/utils-navigation';

import { getSectionPreference } from './getSectionPreference';

type SubmenuItemFragmentWithClientHref = SubmenuItemsFragment & { clientHref?: string };
type MenuItemFragmentWithClientHref = MenuItemFragment & {
  item: { subcats?: Array<SubmenuItemFragmentWithClientHref>; clientHref?: string };
};

const mapMySectionToSubNav = (item: SubmenuItemsFragment): SubmenuItemFragmentWithClientHref => {
  if (item.__typename === 'SectionMenuItem') {
    return {
      ...item,
      clientHref: getSectionPreference(item.href),
    };
  }
  return item;
};

export const mapMySectionToNav = (
  menuItem: MenuItemFragment | SubmenuItemsFragment,
): MenuItemFragmentWithClientHref | SubmenuItemFragmentWithClientHref => {
  if (!isMenuItem(menuItem)) {
    return mapMySectionToSubNav(menuItem);
  }

  if (menuItem.item.__typename === 'SectionMenuItem' || menuItem.item.__typename === 'LinkMenuItem') {
    const subcatsItem = menuItem.item.subcats?.length ? { subcats: menuItem.item.subcats.map(mapMySectionToSubNav) } : null;
    return {
      ...menuItem,
      item: {
        ...menuItem.item,
        ...subcatsItem,
        clientHref: getSectionPreference(menuItem.item.href),
      },
    };
  }
  return menuItem;
};
