import type { IDidomiObject, IUserStatus } from '@didomi/react';

import { getDidomiAsync, getDidomiSync } from './get-didomi';

export async function getDidomiUserStatusAsync(): Promise<IUserStatus> {
  const Didomi = await getDidomiAsync();

  return getDidomiUserStatus(Didomi);
}

export function getDidomiUserStatusSync(): IUserStatus | undefined {
  const Didomi = getDidomiSync();

  if (typeof Didomi === 'undefined') {
    return undefined;
  }

  return getDidomiUserStatus(Didomi);
}

export function getDidomiUserStatus(Didomi: IDidomiObject): IUserStatus {
  return Didomi.getUserStatus();
}
