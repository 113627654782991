import type { ClickOutboundEvent } from '@hubcms/domain-tracking';

export type ClickOutboundData = Omit<ClickOutboundEvent, 'event'>;

export function createClickOutboundEvent(data: ClickOutboundData): ClickOutboundEvent {
  return {
    event: 'click_outbound',
    clickitemblock: data.clickitemblock,
    clickitemname: data.clickitemname,
    clickitemurl: data.clickitemurl,
  };
}
