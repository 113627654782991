import type { NavColumn } from '@hubcms/domain-navigation';

export function activateSection(sectionHref: string) {
  if (!sectionHref) {
    return (navColumn: NavColumn) => navColumn;
  }

  const activeRegExp = new RegExp(`${sectionHref}?$`, 'i');
  return function (navColumn: NavColumn): NavColumn {
    const subItems = navColumn.subItems.map(item => ({ ...item, isActive: activeRegExp.test(item.url || '') }));
    const columnIsActive = activeRegExp.test(navColumn.heading.url || '') || subItems.some(({ isActive }) => isActive);

    return {
      ...navColumn,
      heading: {
        ...navColumn.heading,
        isActive: columnIsActive,
      },
      subItems,
    };
  };
}
