import { type FC, useMemo } from 'react';
import { Button } from '@mediahuis/chameleon-react';
import type { NavItem } from '@hubcms/domain-navigation';
import { useTrackingData, createTrackingClickHandler } from '@hubcms/data-access-tracking';
import { getSubscriptionShopUtmUrl, createClickSubscriptionShopEvent } from '@hubcms/utils-tracking';
import { subscriptionButtonAppearance } from '@hubcms/brand';
import { getThemeDataAttributes } from '@hubcms/utils-theme';
import { useZephrData } from '@hubcms/data-access-zephr';
import { createUrl } from '@hubcms/utils-url';

type NavItemWithUrlAndText = NavItem & { url: string; text: string };

const SubscriptionButton: FC<{ subscriptionMenuItems: NavItem[] }> = ({ subscriptionMenuItems }) => {
  const { subscriber } = useZephrData();
  const trackingData = useTrackingData();
  const isSubscriber = subscriber && Object.keys(subscriber).length > 0;
  const shouldRenderSubscriptionButton = !isSubscriber || subscriber.allowedProducts.length === 0;

  const onClick = useMemo(() => {
    if (typeof window !== 'undefined') {
      const clickSubscriptionShopEvent = createClickSubscriptionShopEvent({ itemdescription: 'btnheader' });
      return createTrackingClickHandler(clickSubscriptionShopEvent);
    }
    return undefined;
  }, []);

  if (!shouldRenderSubscriptionButton) return null;

  return (
    <>
      {subscriptionMenuItems
        .filter(
          (navItem): navItem is NavItemWithUrlAndText => typeof navItem.url === 'string' && typeof navItem.text === 'string',
        )
        .map(({ url, text, target = '_blank', uniqueId = '' }) => {
          const utmUrl = getSubscriptionShopUtmUrl(createUrl(url), trackingData.pageid);

          return (
            <Button
              as="a"
              key={uniqueId}
              onClick={onClick}
              href={utmUrl}
              target={target}
              size="sm"
              appearance={subscriptionButtonAppearance}
              data-testid="subscription-button"
              {...getThemeDataAttributes('premium')}
            >
              {text}
            </Button>
          );
        })}
    </>
  );
};

export default SubscriptionButton;
