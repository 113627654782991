import type { MenuItemFragment, SubmenuItemsFragment } from '@hubcms/domain-cook';

import { isMenuItem } from './isMenuItem';

export function filterSpacers(menuItem: MenuItemFragment | SubmenuItemsFragment) {
  if (isMenuItem(menuItem)) {
    return menuItem.item.__typename !== 'SpacerMenuItem';
  }

  return menuItem.__typename !== 'SpacerMenuItem';
}
