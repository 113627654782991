import { headerLogo } from '@hubcms/brand';
import type { MouseEventHandler, ReactNode } from 'react';
import { Icon, Logo } from '@mediahuis/chameleon-react';
import { Close, Menu, Search } from '@mediahuis/chameleon-theme-wl/icons';
import { Container } from '@hubcms/ui-container';

import { NavigationItem } from '../NavigationItem';

import styles from './header-logo.module.scss';

type HeaderLogoProps = {
  onSearchClicked: () => void;
  onMenuClicked: () => void;
  inlineStartElements?: ReactNode;
  subscriptionButton: ReactNode;
  userMenuButton: ReactNode;
  isMainMenuOpen: boolean;
};

function HeaderLogo({
  onSearchClicked,
  onMenuClicked,
  isMainMenuOpen,
  inlineStartElements,
  subscriptionButton,
  userMenuButton,
}: HeaderLogoProps) {
  const handleSearchClicked: MouseEventHandler = e => {
    onSearchClicked();
    e.stopPropagation();
  };

  const handleMenuClicked: MouseEventHandler = e => {
    onMenuClicked();
  };

  return (
    <Container className={styles.headerLogo}>
      <div className={styles.content}>
        <div className={styles.inlineStart}>{inlineStartElements}</div>
        <NavigationItem
          trackingName="home"
          trackingBlock="header-logo"
          href="/"
          target="_self"
          className={styles.logoContainer}
          data-testid="brand-logo"
        >
          <Logo name={headerLogo} className={styles.logo} />
        </NavigationItem>
        <div className={styles.inlineEnd}>
          {subscriptionButton}
          <div className={styles.inlineEndActions}>
            {userMenuButton}
            <NavigationItem onMouseUp={handleSearchClicked} className={styles.searchButton} data-testid="mobile-search">
              <Icon as={Search} size="sm" />
            </NavigationItem>
            <NavigationItem onMouseUp={handleMenuClicked} className={styles.mainMenuButton} data-testid="mobile-menu">
              <Icon as={isMainMenuOpen ? Close : Menu} size="sm" />
            </NavigationItem>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default HeaderLogo;
