import type { MenuItemFragment, SubmenuItemsFragment } from '@hubcms/domain-cook';
import type { NavItem } from '@hubcms/domain-navigation';

import { isMenuItem } from './isMenuItem';

type NormalizedMenuItem = NavItem & { children: NormalizedMenuItem[] };
type ClientServerHrefs = { clientHref?: string; serverHref?: string };
type NormalizeMenuItemParams = (MenuItemFragment | SubmenuItemsFragment) & ClientServerHrefs;

export function normalizeMenuItem(menuItem: NormalizeMenuItemParams, idx: number): NormalizedMenuItem {
  let children: NormalizedMenuItem[] = [];
  const item = isMenuItem(menuItem) ? menuItem.item : menuItem;

  if (isMenuItem(menuItem) && menuItem.item.__typename !== 'ArticleMenuItem') {
    children = menuItem.item.subcats.map(normalizeMenuItem);
  }

  return {
    children,
    clientUrl: getItemClientHref(item),
    image: getItemImage(item),
    text: getItemLabel(item),
    serverUrl: getItemServerHref(item),
    target: getItemTarget(item),
    uniqueId: generateUniqueId(item, idx),
    url: getItemHref(item),
    subnavItems: getSubnavItems(item),
  };
}

function generateUniqueId(item: SubmenuItemsFragment, idx: number): string {
  switch (item.__typename) {
    case 'SpacerMenuItem':
      return `${item.text}_${idx}`;
    case 'ArticleMenuItem':
      return `${item.href.slice(item.href.lastIndexOf('/'))}`;
    case 'LinkMenuItem':
      return `${item.text}_${item.href}`;
    case 'SectionMenuItem':
      return `${item.href.split('/').reverse()[1]}`;
    default:
      return `no_id`;
  }
}

function getItemHref(item: SubmenuItemsFragment) {
  if (item.__typename === 'SpacerMenuItem') {
    return '';
  }
  return item.href;
}

function getItemClientHref(item: SubmenuItemsFragment & ClientServerHrefs) {
  if (item.__typename === 'SpacerMenuItem') {
    return '';
  }
  return item.clientHref ?? item.href;
}

function getItemServerHref(item: SubmenuItemsFragment & ClientServerHrefs) {
  if (item.__typename === 'SpacerMenuItem') {
    return '';
  }
  return item.serverHref ?? item.href;
}

function getItemLabel(item: SubmenuItemsFragment): string {
  if (item.__typename === 'SpacerMenuItem' && item.text.toLowerCase() === 'spacer') {
    return '';
  }
  if (item.__typename === 'ArticleMenuItem' || item.__typename === 'SectionMenuItem') {
    return item.text || item.label;
  }
  return item.text;
}

function getItemTarget(item: SubmenuItemsFragment): string | undefined {
  return item.__typename === 'LinkMenuItem' ? item.target : undefined;
}

function getItemImage(item: SubmenuItemsFragment): string | undefined {
  if (item.__typename === 'LinkMenuItem' && item.image) {
    return item.image;
  }

  return undefined;
}

function getSubnavItems(item: SubmenuItemsFragment) {
  if (item.__typename === 'ArticleMenuItem') {
    return [];
  }

  return item.subcats?.map(normalizeMenuItem) || [];
}
