import Head from 'next/head';
import type { MetaData } from '../../domain/meta-data';

type AppMetaProps = {
  metaData: MetaData;
  titleSuffix: string;
};

export function AppMeta({ metaData, titleSuffix }: AppMetaProps) {
  const { title, ...metaTags } = metaData;
  const pageTitle = [title, titleSuffix].filter(part => !!part).join(' | ');
  return (
    <Head>
      {pageTitle && <title>{pageTitle}</title>}
      {Object.entries(metaTags)
        .filter(([, content]) => isValidContent(content))
        .flatMap(([name, content]) =>
          Array.isArray(content) ? (
            content
              .filter(isValidContent)
              .map(contentItem => <meta key={`${name}-${contentItem}`} name={name} content={contentItem} />)
          ) : (
            <meta key={name} name={name} content={content} />
          ),
        )}
    </Head>
  );
}

function isValidContent(content: string | string[]): boolean {
  return Array.isArray(content) ? content.some(isValidContent) : content !== '';
}
