import type { FC, PropsWithChildren } from 'react';
import getConfig from 'next/config';
import { authMethod } from '@hubcms/brand';

import { warn } from '@hubcms/utils-monitoring';
import type { AuthMethod } from '@hubcms/domain-auth';

import { providers } from './implementations/providers';

const { publicRuntimeConfig } = getConfig();
export const AUTH0_CLIENT_ID: string = publicRuntimeConfig.AUTH0_CLIENT_ID ?? '';
export const AUTH0_INTEGRATION_SCRIPT_URL: string = publicRuntimeConfig.AUTH0_INTEGRATION_SCRIPT_URL ?? '';

export const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  if (!authMethod) {
    warn('authMethod is not specified - defaulting to noAuth flow');
  }

  const Provider = providers[(authMethod as AuthMethod) || 'none'];

  return <Provider>{children}</Provider>;
};
