export class URLLike implements URL {
  private static origin = 'https://www.example.com';

  private url: URL;

  constructor(url: string | URL, base?: string | URL) {
    this.url = new URL(url, base ?? URLLike.origin);
  }

  static canParse(url: string | URL, base?: string | URL) {
    try {
      // eslint-disable-next-line no-new
      new URLLike(url, base);
      return true;
    } catch {
      return false;
    }
  }

  static create(url: string | URL, base?: string | URL) {
    return new URLLike(url, base);
  }

  get hash() {
    return this.url.hash;
  }

  set hash(value: string) {
    this.url.hash = value;
  }

  get host() {
    return this.url.origin !== URLLike.origin ? this.url.host : '';
  }

  set host(value: string) {
    this.url.host = value;
  }

  get hostname() {
    return this.url.origin !== URLLike.origin ? this.url.hostname : '';
  }

  set hostname(value: string) {
    this.url.hostname = value;
  }

  get href() {
    return this.url.href.replace(URLLike.origin, '');
  }

  set href(value: string) {
    this.url.href = value;
  }

  get origin() {
    return this.url.origin !== URLLike.origin ? this.url.origin : '';
  }

  get password() {
    return this.url.origin !== URLLike.origin ? this.url.password : '';
  }

  set password(value: string) {
    if (this.url.origin !== URLLike.origin) {
      this.url.password = value;
    }
  }

  get pathname() {
    return this.url.pathname;
  }

  set pathname(value: string) {
    this.url.pathname = value;
  }

  get port() {
    return this.url.origin !== URLLike.origin ? this.url.port : '';
  }

  set port(value: string) {
    if (this.url.origin !== URLLike.origin) {
      this.url.port = value;
    }
  }

  get protocol() {
    return this.url.origin !== URLLike.origin ? this.url.protocol : '';
  }

  set protocol(value: string) {
    if (this.url.origin !== URLLike.origin) {
      this.url.protocol = value;
    }
  }

  get search() {
    return this.url.search;
  }

  set search(value: string) {
    this.url.search = value;
  }

  get searchParams() {
    return this.url.searchParams;
  }

  get username() {
    return this.url.origin !== URLLike.origin ? this.url.username : '';
  }

  set username(value: string) {
    if (this.url.origin !== URLLike.origin) {
      this.url.username = value;
    }
  }

  toJSON() {
    return this.href;
  }

  toString() {
    return this.href;
  }
}
