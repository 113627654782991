import cx from 'classnames';
import type { PropsWithChildren } from 'react';

import type { NavItem } from '@hubcms/domain-navigation';
import { Container } from '@hubcms/ui-container';

import { NavigationItem } from '../NavigationItem';

import styles from './header-services.module.scss';

type HeaderServicesBlockProps = PropsWithChildren;

function HeaderServicesBlock({ children, ...props }: HeaderServicesBlockProps) {
  return (
    <div className={styles.servicesBlock} {...props}>
      {children}
    </div>
  );
}

type HeaderServicesProps = PropsWithChildren<{
  startItems: NavItem[];
  endItems: NavItem[];
}>;

function HeaderServices({ startItems, endItems, children }: HeaderServicesProps) {
  return (
    <Container className={cx(styles.headerServices, 'no-print')} data-testid="header-services">
      <div className={styles.content}>
        <HeaderServicesBlock data-testid="header-services-left">
          {startItems.map(({ uniqueId, text, url, target }) => (
            <NavigationItem trackingBlock="Services left" trackingName={text} key={uniqueId} href={url} target={target}>
              {text}
            </NavigationItem>
          ))}
        </HeaderServicesBlock>
        <HeaderServicesBlock data-testid="header-services-middle">{children}</HeaderServicesBlock>
        <HeaderServicesBlock data-testid="header-services-right">
          {endItems.map(({ uniqueId, text, url, target }) => (
            <NavigationItem trackingBlock="Services right" trackingName={text} key={uniqueId} href={url} target={target}>
              {text}
            </NavigationItem>
          ))}
        </HeaderServicesBlock>
      </div>
    </Container>
  );
}

export default HeaderServices;
