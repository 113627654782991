import { type MouseEventHandler, forwardRef } from 'react';
import { Button, Icon } from '@mediahuis/chameleon-react';
import { Person } from '@mediahuis/chameleon-theme-wl/icons';
import cx from 'classnames';
import { useIsScreenSize } from '@hubcms/utils-browser';

import styles from './user-menu-button.module.scss';

type Props = {
  label: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export const UserMenuUnauthenticatedButton = forwardRef<typeof Button, Props>(({ label, onClick }, ref) => {
  const isOnMobile = useIsScreenSize('sm');
  return (
    <Button
      ref={ref}
      appearance={isOnMobile ? 'default' : 'primary'}
      iconLeft={<Icon as={Person} size="sm" />}
      aria-label={label}
      data-testid="login-button"
      className={cx(styles.button, styles.unauthenticatedButton)}
      size="sm"
      onClick={onClick}
    >
      <span className={styles.buttonText}>{label}</span>
    </Button>
  );
});
