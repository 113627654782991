import type { PropsWithChildren } from 'react';
import { useState } from 'react';
import type { NavItem } from '@hubcms/domain-navigation';

import { NavigationContext } from './context';

type NavigationProviderProps = PropsWithChildren<{
  subnavItems?: NavItem[];
}>;

function NavigationProvider({ children, subnavItems = [] }: NavigationProviderProps) {
  const [isAsideMenuOpen, setIsAsideMenuOpen] = useState(false);
  const toggleIsAsideMenuOpen = () => setIsAsideMenuOpen(prevValue => !prevValue);

  return (
    <NavigationContext.Provider value={{ isAsideMenuOpen, toggleIsAsideMenuOpen, subnavItems }}>
      {children}
    </NavigationContext.Provider>
  );
}

export default NavigationProvider;
