import type { IDidomiObject } from '@didomi/react';

import { onDidomiReadyPromise } from './on-didomi-ready';
import { isDidomiDefined } from './is-didomi-defined';

declare global {
  interface Window {
    Didomi?: IDidomiObject;
  }
}

export async function getDidomiAsync(): Promise<IDidomiObject> {
  await onDidomiReadyPromise();

  return window.Didomi as IDidomiObject;
}

export function getDidomiSync(): IDidomiObject | undefined {
  return isDidomiDefined(window.Didomi) ? window.Didomi : undefined;
}
