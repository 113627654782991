/* eslint-disable camelcase */
import type { ClickSubscriptionShopEvent } from '@hubcms/domain-tracking';

export type ClickSubscriptionShopData = Pick<ClickSubscriptionShopEvent, 'itemdescription'>;

export function createClickSubscriptionShopEvent(data: ClickSubscriptionShopData): ClickSubscriptionShopEvent {
  return {
    event: 'page_event',
    eventaction: 'click',
    eventcategory: 'menu',
    eventlabel: 'subscriptionshop',
    itemdescription: data.itemdescription,
    ga_event_name: 'select_promotion',
    method: `menu-www-${data.itemdescription}`,
    items: [
      {
        item_id: '',
        item_name: 'subscriptionshop',
        promotion_id: '',
        promotion_name: data.itemdescription,
      },
    ],
  };
}
