import { domain } from '@hubcms/brand';
import { useTrackingData } from '@hubcms/data-access-tracking';
import {
  isAboshopLink,
  getIsSiteDomain,
  useExternalConsentUrl,
  isJavaScriptDidomiPreferencesShowLink,
} from '@hubcms/utils-navigation';
import { createSubscriptionShopUtmParams, mapSubscriptionShopUtmParams } from '@hubcms/utils-tracking';
import { addSearchParams, createUrl } from '@hubcms/utils-url';
import { LinkText, Paragraph } from '@mediahuis/chameleon-react';
import { showDidomiPreferencesSync } from '@hubcms/utils-didomi';
import cx from 'classnames';
import { type ElementType, type FC, useEffect, useMemo, useState } from 'react';

import type { NavigationItemProps } from './types';
import { createNavigationItemClickHandler } from './createNavigationItemClickHandler';

import styles from './navigation-item.module.scss';

const NavigationItem: FC<NavigationItemProps> = ({
  isActive = false,
  href: initialHref,
  serverHref = initialHref,
  clientHref = initialHref,
  trackingName,
  trackingBlock,
  target,
  className,
  children,
  onClick,
  ...props
}: NavigationItemProps) => {
  const [href, setHref] = useState(serverHref);

  const trackingData = useTrackingData();

  const url = href ? createUrl(href) : null;

  const isAboshopDomain = url ? isAboshopLink(url) : false;
  const isSiteDomain = url ? getIsSiteDomain(url, domain) : false;

  const externalUrl = useExternalConsentUrl(href || null);
  // CW-435: Showing Didomi preferences is configured in CUE as a Menu link with href set to javascript:Didomo.preferences.show()
  const isDidomiShowLink = href ? isJavaScriptDidomiPreferencesShowLink(href) : false;
  const onClickFinal = isDidomiShowLink ? showDidomiPreferencesSync : onClick;

  if (url && isSiteDomain && isAboshopDomain) {
    const aboshopUtmData = mapSubscriptionShopUtmParams({
      // eslint-disable-next-line camelcase
      utm_artid: trackingData.pageid,
      // eslint-disable-next-line camelcase
      utm_internal: trackingBlock,
    });
    const aboshopUtmParams = createSubscriptionShopUtmParams(aboshopUtmData);
    url.search = addSearchParams(url.search, aboshopUtmParams);
  }

  const hrefFinal = isDidomiShowLink ? null : externalUrl || url?.toString();

  const onClickHandler = useMemo(() => {
    return createNavigationItemClickHandler(onClickFinal, trackingBlock, trackingName, href, isSiteDomain, isAboshopDomain);
  }, [href, trackingBlock, trackingName, onClickFinal, isAboshopDomain, isSiteDomain]);

  let Component: ElementType = Paragraph;
  let defaultProps: Record<string, unknown> = {};

  if (href && !isDidomiShowLink) {
    Component = LinkText;
  } else if (onClickFinal) {
    Component = 'button';
    defaultProps = { role: 'button', type: 'button' } as Pick<JSX.IntrinsicElements['button'], 'role' | 'type'>;
  }

  useEffect(() => {
    setHref(clientHref);
  }, [clientHref]);

  return (
    <Component
      className={cx(
        styles.navigationItem,
        { [styles.active]: isActive, [styles.pointer]: Boolean(onClickFinal || props.onMouseUp) },
        className,
      )}
      href={hrefFinal}
      target={target ?? (href ? '_self' : undefined)}
      onClick={onClickHandler}
      {...defaultProps}
      {...props}
    >
      {children}
    </Component>
  );
};

export default NavigationItem;
