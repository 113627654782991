import { type MouseEventHandler, forwardRef } from 'react';
import { Button } from '@mediahuis/chameleon-react';
import cx from 'classnames';

import { InitialsAvatar } from '../InitialsAvatar';
import styles from './user-menu-button.module.scss';

type Props = {
  user: { givenName: string; familyName: string };
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export const UserMenuAuthenticatedButton = forwardRef<typeof Button, Props>(({ user, onClick }, ref) => {
  return (
    <Button
      ref={ref}
      appearance="default"
      iconRight={<InitialsAvatar givenName={user.givenName} familyName={user.familyName} />}
      aria-label={`${user.givenName} ${user.familyName}`}
      data-testid="loggedin-user"
      className={cx(styles.button, styles.authenticatedButton)}
      size="sm"
      onClick={onClick}
    >
      <span className={styles.buttonText}>
        {user.givenName} {user.familyName}
      </span>
    </Button>
  );
});
