import type { FC } from 'react';

import type { NavItem } from '@hubcms/domain-navigation';

import { NavigationItem } from '../NavigationItem';

import styles from './footer-column.module.scss';

type FooterColumnProps = {
  heading: NavItem;
  subItems?: NavItem[];
};

const FooterColumn: FC<FooterColumnProps> = ({ heading, subItems }) => {
  return (
    <div className={styles.footerColumn}>
      <NavigationItem
        href={heading.url}
        target={heading.target}
        className={styles.columnHeader}
        trackingBlock="Footer"
        trackingName={heading.text}
      >
        {heading.text}
      </NavigationItem>
      {subItems?.map(({ uniqueId, text, url, target }) => (
        <NavigationItem
          key={uniqueId}
          href={url}
          target={target}
          className={styles.columnItem}
          trackingBlock="footer"
          trackingName={text}
        >
          {text}
        </NavigationItem>
      ))}
    </div>
  );
};

export default FooterColumn;
