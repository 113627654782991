import { useContext, useMemo } from 'react';

import type { TrackingData } from '@hubcms/domain-tracking';

import { TrackingDataContext } from '../contexts/TrackingDataContext';

export function useTrackingData(initialTrackingData?: TrackingData): TrackingData {
  const contextTrackingData = useContext(TrackingDataContext);

  return useMemo(() => ({ ...initialTrackingData, ...contextTrackingData }), [initialTrackingData, contextTrackingData]);
}
