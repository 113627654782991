import { useRouter } from 'next/router';

/**
 * Parses the articleId from the URL
 * Only use this is if you don't have access to the full cook data (e.g. in _app.tsx)
 */
export function useArticleId() {
  const { query } = useRouter();
  const endOfSlug = query.slug?.at(-1);
  const slugMatches = endOfSlug?.match(/^(\d+)(?=\.html$)/);
  return slugMatches?.[0] || null;
}
