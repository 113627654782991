import type { TrackingData } from '@hubcms/domain-tracking';

import type { ClickData } from './create-navigation-or-outbound-click-event';
import { isClickData } from './is-click-data';

export function mapClickData(data?: TrackingData | null): ClickData | null {
  if (!isClickData(data)) {
    return null;
  }

  return {
    clickitemblock: data.clickitemblock?.toLowerCase() ?? null,
    clickitemname: data.clickitemname?.toLowerCase(),
    clickitemurl: data.clickitemurl,
  };
}
