const NoScrollRequesterIds: Set<string> = new Set();

export const useNoBodyScroll = (componentId: string) => {
  function addNoScrollToBody() {
    if (!NoScrollRequesterIds.size && typeof document !== 'undefined') {
      document.body.parentElement?.classList.add('noscroll');
      document.body.classList.add('noscroll');
    }
    NoScrollRequesterIds.add(componentId);
  }

  function removeNoScrollFromBody() {
    NoScrollRequesterIds.delete(componentId);
    if (!NoScrollRequesterIds.size && typeof document !== 'undefined') {
      document.body.parentElement?.classList.remove('noscroll');
      document.body.classList.remove('noscroll');
    }
  }

  return { addNoScrollToBody, removeNoScrollFromBody };
};
