import { type MutableRefObject, useEffect, useRef } from 'react';

function getParentNodes(element: Node): Node[] {
  if (element.parentNode) {
    return [element.parentNode as Node].concat(...getParentNodes(element.parentNode));
  }
  return [];
}

export const useOnClickOutside = <T extends HTMLElement = HTMLElement>(handler: (event: Event) => void, withEscape?: boolean) => {
  const elementRef = useRef<T>() as MutableRefObject<T>;

  useEffect(() => {
    const listener = (event: MouseEvent) => {
      const htmlNode = event.target as T;
      const parentNodes = getParentNodes(htmlNode);
      if (!elementRef.current || [htmlNode, ...parentNodes].some(node => elementRef.current.contains(node))) {
        return;
      }
      handler(event);
    };
    const escapeListener = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handler(event);
      }
    };
    document.addEventListener('mouseup', listener);
    if (withEscape) {
      document.addEventListener('keyup', escapeListener);
    }
    return () => {
      document.removeEventListener('mouseup', listener);
      if (withEscape) {
        document.removeEventListener('keyup', escapeListener);
      }
    };
  }, [elementRef, handler, withEscape]);

  return { elementRef };
};
