import type { TrackingData } from '@hubcms/domain-tracking';

import { isClickData } from './is-click-data';
import type { ClickSubscriptionShopData } from './create-click-subscription-shop-event';

export function mapClickSubscriptionShopData(data?: TrackingData | null): ClickSubscriptionShopData | null {
  if (!isClickData(data)) {
    return null;
  }

  return {
    itemdescription: data.clickitemblock?.toLowerCase() ?? null,
  };
}
