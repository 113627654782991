import { type MutableRefObject, useCallback, useRef, useState } from 'react';

export function useElementOffset() {
  const elementRef = useRef<HTMLElement>() as MutableRefObject<HTMLElement>;
  const [offset, setOffset] = useState<number>(0);

  const calculateOffset = useCallback(() => {
    if (elementRef.current) {
      let cumulativeOffset = 0;
      let currentElement = elementRef.current;
      do {
        cumulativeOffset += currentElement.offsetTop || 0;
        currentElement = currentElement.offsetParent as HTMLElement;
      } while (currentElement);

      setOffset(cumulativeOffset);
    }
  }, [elementRef]);

  return { elementRef, offset, calculateOffset };
}
