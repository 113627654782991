import type { MouseEvent as ReactMouseEvent } from 'react';
import type { TrackingEvent } from '@hubcms/domain-tracking';
import { createAsyncMouseEventHandler } from '@hubcms/utils-event';

import { sendTrackingEvent } from './send-tracking-event';

type TrackingAction<T> = (event: T) => Promise<void> | void;

type TrackingHandler<T, K = TrackingAction<T> | void> = (event: T) => Promise<K> | K;

export function createTrackingClickHandler<T extends MouseEvent | ReactMouseEvent>(
  trackingEvent: TrackingEvent | TrackingEvent[],
  trackingHandler?: TrackingHandler<T>,
) {
  return createAsyncMouseEventHandler(async (clickEvent: T) => {
    const [, trackingAction] = await Promise.all([sendTrackingEvent(trackingEvent), trackingHandler?.(clickEvent)]);
    return trackingAction;
  });
}
